import { render, staticRenderFns } from "./Days.vue?vue&type=template&id=36c32b42&scoped=true&"
import script from "./Days.vue?vue&type=script&lang=ts&"
export * from "./Days.vue?vue&type=script&lang=ts&"
import style0 from "./Days.vue?vue&type=style&index=0&id=36c32b42&lang=scss&scoped=true&"
import style1 from "./Days.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36c32b42",
  null
  
)

export default component.exports
import {QItem,QTooltip} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QItem,QTooltip})
