






























import { Component, VModel, Vue, Prop } from 'vue-property-decorator'
import { Freq } from '@tada-team/tdproto-ts'

@Component
export default class RepeatabilityDays extends Vue {
  @VModel({
    required: true,
    type: Object,
  }) freq!: Freq

  @Prop({
    type: Number,
    required: true,
  }) currentDay!: number

  private days: { title: string, number: number }[] = [{
    title: this.$t('meetings.repeatability.1').toString(),
    number: 1,
  }, {
    title: this.$t('meetings.repeatability.2').toString(),
    number: 2,
  }, {
    title: this.$t('meetings.repeatability.3').toString(),
    number: 3,
  }, {
    title: this.$t('meetings.repeatability.4').toString(),
    number: 4,
  }, {
    title: this.$t('meetings.repeatability.5').toString(),
    number: 5,
  }, {
    title: this.$t('meetings.repeatability.6').toString(),
    number: 6,
  }, {
    title: this.$t('meetings.repeatability.7').toString(),
    number: 7,
  }]

  private get freqDays () {
    return this.days.map(d => ({ ...d, isSelected: this.isSelectedDay(d.number) }))
  }

  private isSelectedDay (day: number) {
    return this.freq?.freqDays?.includes(day)
  }

  private async selectDay (day: number) {
    if (!this.freq?.freqDays) return
    if (day === this.currentDay) return

    const index = this.freq.freqDays.findIndex(d => day === d)

    if (index === -1) {
      this.freq.freqDays.push(day)
    } else {
      this.freq.freqDays.splice(index, 1)
    }

    this.$emit('updateFrequencyDescription')
  }
}
